$(document).ready(function(){
	let headerIndent;
	if ($(window).width() > 550) {
		headerIndent = 140;
	} else {
		headerIndent = 20;
	}
	$(window).on('scroll', function() {
		if ($(window).scrollTop() > headerIndent) {
			$('.header').addClass('header_active');
		} else {
			$('.header').removeClass('header_active');
		}
	});

	// Add smooth scrolling to all links
	$(".header__nav-link").on('click', function(event) {

	if (this.hash !== "") {
		event.preventDefault();
		var hash = this.hash;
		$('html, body').animate({
		  scrollTop: $(hash).offset().top
		}, 800, function(){
		  window.location.hash = hash;
		});
		}
	});

	// mobile menu

	$('.mobile-menu-button').on('click', function() {
		$('.mobile-menu').animate({
			opacity: 1,
			right: "+=100",
			width: "toggle",
		  }, 1000, "linear");
		$('.mobile-menu__nav').animate({
			opacity: 1,
		}, 1100, "linear");
	});

	function closeMenu() {
		$('.mobile-menu').animate({
			opacity: 0,
			right: "+=0",
			width: "toggle",
		  }, 1000, "linear");
		$('.mobile-menu__nav').animate({
			opacity: 0,
		}, 800, "linear");
	};

	$('.mobile-menu__close').on('click', function() {
		closeMenu()
	});

	$('.mobile-menu__nav-link').on('click', function() {
		setTimeout(closeMenu(), 1000);
	});

});

