$(document).ready(function(){
	function checkFirstTab(activeTab) {
		if (activeTab === 1) {
			$('.tabs__controls .section-title:first-child').addClass('section-title_active');
			$('.tabs__controls .section-title:nth-child(2)').removeClass('section-title_active');
			// $('.proj-carousel17').show();
			// $('.proj-carousel16').hide();
			$('.proj-carousel16').show();
			$('.proj-carousel17').hide();
		} else if (activeTab === 2) {
			$('.tabs__controls .section-title:nth-child(2)').addClass('section-title_active');
			$('.tabs__controls .section-title:first-child').removeClass('section-title_active');
			$('.proj-carousel17').show();
			$('.proj-carousel16').hide();
			// $('.proj-carousel16').show();
			// $('.proj-carousel17').hide();
		} else return
	}

	if($('#tab2-16').prop( "checked" ) === true) {
		checkFirstTab(1);
	}
	if($('#tab2-17').prop( "checked" ) === true) {
		checkFirstTab(2);
	}


	$('#tab2-16').on('change', function() {
		if($('#tab2-16').prop( "checked" ) === true) {
			checkFirstTab(1);
		}

	});
	$('#tab2-17').on('change', function() {
		if($('#tab2-17').prop( "checked" ) === true) {
			checkFirstTab(2);
		}
	});
});
