$(document).ready(function(){
	function enableSwiper() {
		var tabSwiper1 = new Swiper ('.proj-carousel17', {
			speed: 500,
			direction: 'horizontal',
			loop: false,
			initialSlide: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
		var tabSwiper2 = new Swiper ('.proj-carousel16', {
			speed: 500,
			direction: 'horizontal',
			loop: false,
			initialSlide: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
		$('.swiper-button-prev').show();
		$('.swiper-button-next').show();
	}

	function enableGallerySlider() {
		var galleryThumbs = new Swiper('.gallery-thumbs', {
			spaceBetween: 5,
			slidesPerView: 7,
			freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
		});
		var galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 10,
			loop: true,
			navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
			},
			thumbs: {
				swiper: galleryThumbs
			}
		});
		$('.swiper-button-prev').show();
		$('.swiper-button-next').show();
	}

	function disabledGallerySwiper() {
		$('.swiper-button-prev').hide();
		$('.swiper-button-next').hide();
		$('.swiper-wrapper').addClass('swiper-wrapper_disabled');
		galleryThumbs.destroy( true, true );
		galleryTop.destroy( true, true );
	}

	function disabledSwiper() {
		$('.swiper-button-prev').hide();
		$('.swiper-button-next').hide();
		$('.swiper-wrapper').addClass('swiper-wrapper_disabled');
		tabSwiper1.destroy( true, true );
		tabSwiper2.destroy( true, true );
	}

	if($( window ).width() <= 937) {
		disabledGallerySwiper();
		disabledSwiper();
	} else {
		enableGallerySlider();
		enableSwiper();
	}


	$( window ).resize(function() {
		if($( window ).width() <= 937) {
			disabledGallerySwiper();
			disabledSwiper();
		} else {
			enableGallerySlider();
			enableSwiper();
		};
	});

});
